<template lang="pug">
v-dialog(v-model="show" max-width="500px" persistent)
  v-card.py-2
    v-card-title.d-flex.justify-space-between
      h3.text-center {{ title }}
    v-card-text
      v-form(v-model="valid" ref="form")
        v-text-field(
          v-model="form.concept" 
          :rules="[$rules.required]" 
          label="Concepto" 
          outlined dense
        )
        v-text-field(
          v-model="form.supplier" 
          label="Proveedor (opcional)" 
          outlined dense
        )
        v-currency-field(
          dense outlined 
          prefix="S/" 
          :min="0" 
          :rules="[$rules.numeric, $rules.minValue(form.total_price, 0)]" 
          label="Total de la compra" 
          v-model.number="form.total_price"
        )
        // Campo para indicar si el costo es a crédito
        v-switch(
          v-model="form.is_credit" 
          label="¿Costo a crédito?" 
          outlined dense
        )
        // Mostrar campos adicionales si se activa la opción de crédito
        v-slide-y-transition
          div(v-if="form.is_credit")
            v-text-field(
              v-model.number="form.credit_days" 
              type="number" 
              :rules="[$rules.required]" 
              label="Días de crédito" 
              outlined dense
            )
            v-currency-field(
              dense outlined 
              prefix="S/" 
              :min="0" 
              :rules="[$rules.numeric]" 
              label="Pago parcial" 
              v-model.number="form.partial_payment"
            )
            v-text-field(
              v-model="form.total_payment_date" 
              label="Fecha de pago total" 
              outlined dense 
              type="date"
              placeholder="DD/MM/AAAA"
            )
    .py-3.d-flex.justify-center
      v-btn(color="primary" outlined @click="close") Cerrar
      v-btn.ml-8(
        color="primary" 
        @click="confirmed" 
        :loading="loading" 
        :disabled="!valid"
      ) {{ action === 'create' ? 'Crear' : 'Editar' }}
  </template>
  
  <script>
  export default {
    data() {
      return {
        form: {
          id: null,
          concept: "",
          supplier: "",
          total_price: 0,
          // Campos para crédito (is_credit se define como false para nuevos registros; en registros antiguos se recibirá null)
          is_credit: false,
          credit_days: 0,
          partial_payment: 0,
          total_payment_date: "",
        },
        show: false,
        loading: false,
        action: 'create',
        valid: false,
      };
    },
    computed: {
      title() {
        return this.action === 'create' ? 'NUEVO COSTO EXTRA' : 'EDITAR COSTO EXTRA';
      }
    },
    methods: {
      close() {
        this.show = false;
        this.loading = false;
      },
      open(action, item) {
        this.action = action;
        if (action === 'edit') {
          this.form = { ...item };
        } else {
          this.form = {
            id: null,
            concept: "",
            supplier: "",
            total_price: 0,
            is_credit: false,
            credit_days: 0,
            partial_payment: 0,
            total_payment_date: "",
          };
        }
        this.show = true;
        this.loading = false;
        this.$nextTick(() => {
          this.$refs.form.resetValidation();
        });
      },
      async confirmed() {
        this.loading = true;
        try {
          await this.$store.dispatch('upsertPurchaseExtraCost', { purchaseId: this.$route.params.id, payload: this.form });
          this.$emit('done');
          this.close();
        } catch (error) {
          console.error(error);
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .text-center {
    text-align: center;
  }
  </style>
  