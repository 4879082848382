<template lang="pug">
v-container.px-8(fluid)
  dialog-action-purchase(ref="dialogActionPurchase" @done="loadPurchase")
  dialog-action-purchase-item(ref="dialogActionPurchaseItem" :purchase="purchase" @done="loadPurchase")
  dialog-action-purchase-extra-cost(ref="dialogActionPurchaseExtraCost" :purchase="purchase" @done="loadPurchase")
  dialog-confirm(ref="dialogDeleteItem" primaryText="Eliminar" secondaryText="Cancelar" @actionPressed="deletePurchaseItem")
  dialog-confirm(ref="dialogDeleteExtra" primaryText="Eliminar" secondaryText="Cancelar" @actionPressed="deletePurchaseExtraCost")
  dialog-confirm(ref="dialogConfirmApprove" primaryText="Aprobar" secondaryText="Cancelar" @actionPressed="confirmApproveStatus")
  v-row
    v-col(cols='12')
      v-toolbar(flat)
        v-toolbar-title.title.d-flex.align-center 
          v-btn(icon @click="$router.go(-1)") 
            v-icon mdi-arrow-left
          | Detalle de la compra
          v-chip.ml-8(small label :color="$decoders.purchaseStatusColor(purchase.status)") {{ $decoders.purchaseStatus(purchase.status) }}
        v-spacer
        v-btn(color="success" v-if="purchase.status !== 'approved'" @click="openConfirmApprove") Aprobar Compra 
      v-divider
  v-overlay(color='white' :value='generalLoading')
    v-progress-circular(color='primary' indeterminate size='64')
  v-row(wrap)
    v-col(cols='4')
      v-card.elevation-2
        v-card-title Total en productos
        v-card-text
          h3 {{ formatCurrency(purchase.total_items_cost) }}
    v-col(cols='4')
      v-card.elevation-2
        v-card-title Total Costos Extras
        v-card-text
          h3 {{ formatCurrency(purchase.total_extra_costs) }}
    v-col(cols='4')
      v-card.elevation-2
        v-card-title Costo Total
        v-card-text
          h3 {{ formatCurrency(purchase.total_cost) }}
  v-row
    v-col(cols='12')
      v-card.elevation-2.mb-4
        v-card-title Comprador
        v-card-text
          div
            span.font-weight-bold Nombre: 
            span {{ purchase.buyer.first_name }} {{ purchase.buyer.last_name }}
          div
            span.font-weight-bold Email: 
            span {{ purchase.buyer.email }}
          div
            span.font-weight-bold Fecha de Compra: 
            span {{ purchase.purchase_date }}
  v-tabs(v-model="tab")
    v-tab Productos
    v-tab Costos Extras
  v-divider
  .text-right.py-4(v-if="purchase.status !== 'approved'")
    v-btn(v-if="tab === 0" color="primary" @click="openCreateItem") Nuevo Producto 
    v-btn(v-if="tab === 1" color="primary" @click="openCreateExtraCost") Nuevo Costo Extra    
  v-tabs-items(v-model="tab")
    v-tab-item
      v-data-table(
        :items="purchase.items"
        :headers="itemsHeaders"
        no-data-text="No se encontraron productos"
        :footer-props="{'items-per-page-text': 'Por página', 'items-per-page-options': [100, 200]}"
      )
        template(v-slot:item.product_name="{ item }")
          span {{ item.product_name }}
        template(v-slot:item.supplier="{ item }")
          span {{ item.supplier }}
        template(v-slot:item.quantity="{ item }")
          span {{ item.quantity }} {{ item.unit }}
        template(v-slot:item.unit_price="{ item }")
          span {{ formatCurrency(item.unit_price) }}
        template(v-slot:item.total_price="{ item }")
          span {{ formatCurrency(item.total_price) }}
        // Columna: Pago Parcial (se muestra si is_credit es true o false; si es null se deja vacío)
        template(v-slot:item.partial_payment="{ item }")
          span {{ item.is_credit !== null ? formatCurrency(item.partial_payment) : "" }}
        // Columna: Deuda
        template(v-slot:item.debt="{ item }")
          strong {{ getDebt(item) !== "" ? formatCurrency(getDebt(item)) : "" }}
        // Columna: Estado de pago (solo si is_credit no es null)
        template(v-slot:item.payment_status="{ item }")
          template(v-if="item.is_credit !== null")
            strong(:class="[ (item.total_payment_date && item.total_payment_date.trim() !== '') || getDebt(item)===0 ? 'success--text' : 'red--text']") {{ getPaymentStatus(item) }}
        // Columna: Fecha de pago (formateada en dd/mm/aaaa; se muestra solo si is_credit no es null)
        template(v-slot:item.payment_date="{ item }")
          span(v-if="item.is_credit === null")
          span(v-else) {{ formatDatePeru(getPaymentDate(item)) }}
        template(v-slot:item.actions="{ item }")
          v-menu(bottom, close-on-content-click, left)
            template(v-slot:activator="{ on, attrs }")
              v-btn(icon, v-bind="attrs", v-on="on")
                v-icon mdi-dots-vertical
            v-list
              v-list-item(@click="$refs.dialogActionPurchaseItem.open('edit', item)")
                v-list-item-title Editar
              v-list-item(@click="openModalDeleteItem(item)")
                v-list-item-title Eliminar
      // Resumen de productos a crédito
      v-row.mt-4(v-if="pendingItemsCount > 0")
        v-col(cols="12" class="text-right")
          strong Productos pendientes: {{ pendingItemsCount }} - Monto total por pagar: {{ formatCurrency(totalItemsPending) }}
    v-tab-item
      v-data-table(
        :items="purchase.extra_costs"
        :headers="extraCostsHeaders"
        no-data-text="No se encontraron costos extras"
        :footer-props="{'items-per-page-text': 'Por página', 'items-per-page-options': [100, 200]}"
      )
        template(v-slot:item.concept="{ item }")
          span {{ item.concept }}
        template(v-slot:item.supplier="{ item }")
          span {{ item.supplier }}
        template(v-slot:item.total_price="{ item }")
          span {{ formatCurrency(item.total_price) }}
        // Columna: Pago Parcial
        template(v-slot:item.partial_payment="{ item }")
          span {{ item.is_credit !== null ? formatCurrency(item.partial_payment) : "" }}
        // Columna: Deuda
        template(v-slot:item.debt="{ item }")
          strong {{ getDebt(item) !== "" ? formatCurrency(getDebt(item)) : "" }}
        // Columna: Estado de pago
        template(v-slot:item.payment_status="{ item }")
          template(v-if="item.is_credit !== null")
            strong(:class="[ (item.total_payment_date && item.total_payment_date.trim() !== '') || getDebt(item)===0 ? 'success--text' : 'red--text']") {{ getPaymentStatus(item) }}
        // Columna: Fecha de pago
        template(v-slot:item.payment_date="{ item }")
          span(v-if="item.is_credit === null")
          span(v-else) {{ formatDatePeru(getPaymentDate(item)) }} 
        template(v-slot:item.actions="{ item }")
          v-menu(bottom, close-on-content-click, left)
            template(v-slot:activator="{ on, attrs }")
              v-btn(icon, v-bind="attrs", v-on="on")
                v-icon mdi-dots-vertical
            v-list
              v-list-item(@click="$refs.dialogActionPurchaseExtraCost.open('edit', item)")
                v-list-item-title Editar
              v-list-item(@click="openModalDeleteExtra(item)")
                v-list-item-title Eliminar
      // Resumen de costos extras a crédito
      v-row.mt-4(v-if="pendingExtrasCount > 0")
        v-col(cols="12" class="text-right")
          strong Costos extras pendientes: {{ pendingExtrasCount }} - Monto total por pagar: {{ formatCurrency(totalExtrasPending) }}
  // Resumen general de créditos (productos + extras)
  v-row.mt-4
    v-col(cols="12" class="text-right")
      strong Total pendientes (Productos + Costos extras): {{ pendingTotalCount }} - Monto total por pagar: {{ formatCurrency(pendingTotalAmount) }}
</template>

<script>
import DialogConfirm from "../../../components/shared/DialogConfirm.vue";
import DialogActionPurchase from "../../../components/admin/business/DialogActionPurchase.vue";
import DialogActionPurchaseItem from "../../../components/admin/business/DialogActionPurchaseItem.vue";
import DialogActionPurchaseExtraCost from "../../../components/admin/business/DialogActionPurchaseExtraCost.vue";
export default {
  components: {
    DialogConfirm,
    DialogActionPurchase,
    DialogActionPurchaseItem,
    DialogActionPurchaseExtraCost,
  },
  data() {
    return {
      tab: 0,
      generalLoading: false,
      toDelete: null,
      purchase: {
        items: [],
        extra_costs: [],
        total_items_cost: 0,
        total_extra_costs: 0,
        total_cost: 0,
        buyer: {
          first_name: "",
          last_name: "",
          email: "",
        },
        purchase_date: "", // formato "YYYY-MM-DD"
        purchase_timestamp: 0,
      },
      itemsHeaders: [
        { text: "Producto", value: "product_name" },
        { text: "Proveedor", value: "supplier" },
        { text: "Cantidad", value: "quantity" },
        { text: "Precio unitario", value: "unit_price" },
        { text: "Precio Total", value: "total_price" },
        { text: "Pago Parcial", value: "partial_payment", align: "right" },
        { text: "Deuda", value: "debt", align: "right" },
        { text: "Estado de pago", value: "payment_status", align: "center" },
        { text: "Fecha de pago", value: "payment_date", align: "center" },
        { text: "", align: "start", sortable: false, value: "actions", class: "primary--text" },
      ],
      extraCostsHeaders: [
        { text: "Concepto", value: "concept" },
        { text: "Proveedor", value: "supplier" },
        { text: "Precio Total", value: "total_price" },
        { text: "Pago Parcial", value: "partial_payment", align: "right" },
        { text: "Deuda", value: "debt", align: "right" },
        { text: "Estado de pago", value: "payment_status", align: "center" },
        { text: "Fecha de pago", value: "payment_date", align: "center" },
        { text: "", align: "start", sortable: false, value: "actions", class: "primary--text" },
      ],
    };
  },
  computed: {
    pendingItemsCount() {
      return this.purchase.items.filter(
        (item) =>
          item.is_credit &&
          (!item.total_payment_date || item.total_payment_date.trim() === "") &&
          item.total_price - item.partial_payment > 0
      ).length;
    },
    totalItemsPending() {
      return this.purchase.items
        .filter(
          (item) =>
            item.is_credit &&
            (!item.total_payment_date || item.total_payment_date.trim() === "")
        )
        .reduce((acc, item) => acc + Math.max(0, item.total_price - item.partial_payment), 0);
    },
    pendingExtrasCount() {
      return this.purchase.extra_costs.filter(
        (extra) =>
          extra.is_credit  &&
          (!extra.total_payment_date || extra.total_payment_date.trim() === "") &&
          extra.total_price - extra.partial_payment > 0
      ).length;
    },
    totalExtrasPending() {
      return this.purchase.extra_costs
        .filter(
          (extra) =>
            extra.is_credit &&
            (!extra.total_payment_date || extra.total_payment_date.trim() === "")
        )
        .reduce((acc, extra) => acc + Math.max(0, extra.total_price - extra.partial_payment), 0);
    },
    pendingTotalCount() {
      return this.pendingItemsCount + this.pendingExtrasCount;
    },
    pendingTotalAmount() {
      return this.totalItemsPending + this.totalExtrasPending;
    },
  },
  methods: {
    formatCurrency(value) {
      return new Intl.NumberFormat("es-PE", {
        style: "currency",
        currency: "PEN",
      }).format(value);
    },
    // Convierte "YYYY-MM-DD" a "dd/mm/aaaa"
    formatDatePeru(dateStr) {
      if (!dateStr) return "";
      const parts = dateStr.split("-");
      if (parts.length !== 3) return dateStr;
      return `${parts[2]}/${parts[1]}/${parts[0]}`;
    },
    // Si is_credit es null => sistema no usado (retorna cadena vacía)
    // Si es false => se usa pero no se aplicó, deuda = 0
    // Si es true => si no hay fecha de pago, deuda = total_price - partial_payment; si hay fecha, deuda = 0.
    getDebt(item) {
      if (item.is_credit === null) return "";
      if (item.is_credit === false) return 0;
      // Ahora is_credit es true
      if (item.total_payment_date && item.total_payment_date.trim() !== "") return 0;
      const debt = item.total_price - item.partial_payment;
      return debt > 0 ? debt : 0;
    },
    getPaymentStatus(item) {
      if (item.is_credit === null) return "-";
      if (item.is_credit === false) return "Pagado";
      // is_credit === true
      return (item.total_payment_date && item.total_payment_date.trim() !== "") || this.getDebt(item) === 0
        ? "Pagado"
        : "Pendiente";
    },
    // Si is_credit es null o false, se retorna cadena vacía (no aplica)
    // Si es true, se muestra la fecha registrada o, en su defecto, la fecha esperada (calculada sumando credit_days a purchase_date)
    getPaymentDate(item) {
      if (item.is_credit === null) return "";
      if (item.is_credit === false) return "";
      if (item.total_payment_date && item.total_payment_date.trim() !== "") return item.total_payment_date;
      return this.computeExpectedPaymentDate(item.credit_days);
    },
    // Calcula la fecha esperada de pago a partir de purchase_date y credit_days
    computeExpectedPaymentDate(creditDays) {
      if (!this.purchase.purchase_date) return "";
      const parts = this.purchase.purchase_date.split("-");
      const year = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10);
      const day = parseInt(parts[2], 10);
      const date = new Date(year, month - 1, day);
      date.setDate(date.getDate() + creditDays);
      const yyyy = date.getFullYear();
      const mm = ("0" + (date.getMonth() + 1)).slice(-2);
      const dd = ("0" + date.getDate()).slice(-2);
      return `${yyyy}-${mm}-${dd}`;
    },
    openModalDeleteItem(item) {
      this.toDelete = item;
      this.$refs.dialogDeleteItem.open(
        "Eliminar Compra",
        `¿Estás seguro de eliminar el producto <b>${item.product_name}</b>? Esta acción no se puede deshacer.`
      );
    },
    openModalDeleteExtra(extra) {
      this.toDelete = extra;
      this.$refs.dialogDeleteExtra.open(
        "Eliminar Costo Extra",
        `¿Estás seguro de eliminar el costo extra <b>${extra.concept}</b>? Esta acción no se puede deshacer.`
      );
    },
    openCreateItem() {
      this.$refs.dialogActionPurchaseItem.open("create");
    },
    openCreateExtraCost() {
      this.$refs.dialogActionPurchaseExtraCost.open("create");
    },
    async loadPurchase() {
      this.generalLoading = true;
      try {
        let purchase = await this.$store.dispatch("findPurchaseByID", this.$route.params.id);
        this.purchase = purchase;
      } catch (error) {
        console.error("Error al cargar la compra:", error);
      }
      this.generalLoading = false;
    },
    createPurchase() {
      this.$refs.dialogActionPurchase.open("create");
    },
    async deletePurchaseItem() {
      if (this.toDelete) {
        try {
          await this.$store.dispatch("deletePurchaseItem", {
            purchaseId: this.purchase.id,
            purchaseItemId: this.toDelete.id,
          });
          await this.loadPurchase();
          this.$refs.dialogDeleteItem.close();
        } catch (error) {
          this.$refs.dialogInfo.open("Error", "No se pudo eliminar");
        }
      }
    },
    async deletePurchaseExtraCost() {
      if (this.toDelete) {
        try {
          await this.$store.dispatch("deletePurchaseExtraCost", {
            purchaseId: this.purchase.id,
            extraCostId: this.toDelete.id,
          });
          await this.loadPurchase();
          this.$refs.dialogDeleteExtra.close();
        } catch (error) {
          this.$refs.dialogInfo.open("Error", "No se pudo eliminar");
        }
      }
    },
    openConfirmApprove() {
      this.$refs.dialogConfirmApprove.open(
        "Confirmar revisión",
        "¿Estás seguro que deseas confirmar la revisión de la compra?"
      );
    },
    async confirmApproveStatus() {
      try {
        await this.$store.dispatch("changePurchaseStatus", { purchaseId: this.purchase.id, status: "approved" });
        await this.loadPurchase();
        this.$refs.dialogConfirmApprove.close();
      } catch (error) {
        this.$refs.dialogInfo.open("Error", "No se pudo confirmar la revisión");
      }
    },
    // Calcula la deuda total de la compra, sumando únicamente la deuda de los elementos cuyo is_credit sea true.
    // Si no hay ningún elemento con is_credit true (aunque pueda haber con false), se retorna 0.
    getPurchaseDebt(purchase) {
      const items = purchase.items || [];
      const extras = purchase.extra_costs || [];
      const debtItems = items.filter(i => i.is_credit === true).reduce((acc, i) => {
        if (i.total_payment_date && i.total_payment_date.trim() !== "") return acc;
        const debt = i.total_price - i.partial_payment;
        return acc + (debt > 0 ? debt : 0);
      }, 0);
      const debtExtras = extras.filter(e => e.is_credit === true).reduce((acc, e) => {
        if (e.total_payment_date && e.total_payment_date.trim() !== "") return acc;
        const debt = e.total_price - e.partial_payment;
        return acc + (debt > 0 ? debt : 0);
      }, 0);
      return debtItems + debtExtras;
    },
  },
  async beforeMount() {
    await this.$store.dispatch("loadProductsSimple");
    await this.loadPurchase();
  },
};
</script>

<style lang="scss">
.purchase-view {
  .v-data-table__wrapper {
    table {
      tbody {
        tr:nth-child(even) { td { background: transparent; } }
        tr:nth-child(odd) { background: #f4f9f7; }
        tr { &:hover { cursor: pointer; background-color: #c8fbe1 !important; } }
      }
    }
  }
}
</style>
