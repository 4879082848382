<template lang="pug">
v-container.px-8
  dialog-confirm(ref="dialogFactura" primaryText="Emitir factura" secondaryText="Salir" @actionPressed="emitirFactura")
  dialog-excel-business-orders(ref="dialogExcelBusinessOrders")
  dialog-excel-business-orders-by-dispatch(ref="dialogExcelBusinessOrdersByDispatch")
  v-dialog(v-model="showObs" width="400px")
    v-card 
      v-card-title.justify-center Indicación del pedido 
      v-card-text 
        div(v-html="wrapInParagraphs(currentObs)")
  v-row
    v-col(cols='12')
      v-toolbar(flat)
        v-toolbar-title.title Ordenes de compra
        v-spacer 
        v-menu(offset-y)
          template(v-slot:activator="{ on, attrs }")
            v-btn(color="green" dark small plain v-bind="attrs" v-on="on")
              .text-decoration-underline.font-weight-bold Descargar órdenes de compra
              v-icon(small) mdi-dots-vertical
          v-list
            v-list-item(@click="$refs.dialogExcelBusinessOrders.open()")
              v-list-item-title Lista de compra e inventario
            v-list-item(@click="$refs.dialogExcelBusinessOrdersByDispatch.open()")
              v-list-item-title Facturados por fecha de despacho
      v-divider
  v-overlay(color='white' :value='generalLoading')
    v-progress-circular(color='primary' indeterminate size='64')
  v-row(wrap)
    v-col.d-flex.justify-space-between.align-center(cols='12')
      v-autocomplete.mr-5(dense hide-details @change="loadItems" style="max-width: 250px;" outlined v-model="search.business_id" :items="businesses" item-value="id" label="Filtrar por empresa" item-text="business_name")
      v-select(dense outlined hide-details @change="loadItems" style="max-width: 200px;" v-model="search.has_billing" :items="billing_combo" label="Filtrar por facturación" item-text="text")
      v-spacer 
      v-btn(color="primary" @click="createOrder") Nueva Orden
    v-col(cols='12')
      v-data-table(
        :items="items"  
        :loading="loading" 
        :headers="headers" 
        :server-items-length="totalItems" 
        :footer-props="{itemsPerPageOptions: [20, 50], 'items-per-page-text': 'Órdenes por página: '}"  
        :items-per-page.sync="search.docsPerPage" 
        :page.sync="search.page" 
        no-data-text="No se encontraron órdenes" 
        @update:page="loadItems" 
        @update:items-per-page="loadItems"
      )          
        template(v-slot:[`item.created_at`]='{ item }')
          span {{formatDate(item.created_at)}}
     
        template(v-slot:[`item.actions`]='{ item }')
          v-menu(bottom close-on-content-click left)
            template(v-slot:activator='{ on, attrs }')
              v-btn(icon='' v-bind='attrs' v-on='on')
                v-icon mdi-dots-vertical
            v-list
              v-list-item(@click='openEdit(item)')
                v-list-item-title Editar
              v-list-item(v-if="!item.has_billing && item.status !== 'not_delivered'" @click="openFacturaModal(item)")
                v-list-item-title Emitir Factura
              v-list-item(v-if="item.has_billing && !item.has_nota_credito" @click="$router.push(`/corporativo/ordenes/${item.id}/notacredito`)")
                v-list-item-title Emitir Nota de crédito

        template(v-slot:[`item.has_billing`]='{ item }')
          b.text-decoration-underline(v-if="!item.facturacion_tefacturo_response") SIN FACTURAR {{ item.is_combination_order}}
          b.text-decoration-underline.hover.green--text( @click="downloadBilling(item)" v-else-if="item.facturacion_tefacturo_response.ok_response") {{item.facturacion_tefacturo_response.ok_response.serie}}-{{item.facturacion_tefacturo_response.ok_response.numero}}
          b.text-decoration-underline.hover.red--text(v-else @click="$refs.dialogInfo.open('Error de facturación', item.facturacion_tefacturo_response.error_message)") Error al facturar
        template(v-slot:[`item.has_nota_credito`]='{ item }')
          b.text-decoration-underline(v-if="!item.nota_credito_tefacturo_response") SIN N.C
          b.text-decoration-underline.hover.green--text( @click="downloadNotaCredito(item)" v-else-if="item.nota_credito_tefacturo_response.ok_response") {{item.nota_credito_tefacturo_response.ok_response.serie}}-{{item.nota_credito_tefacturo_response.ok_response.numero}}
          b.text-decoration-underline.hover.red--text(v-else @click="$refs.dialogInfo.open('Error de facturación', item.nota_credito_tefacturo_response.error_message)") Error al facturar
        template(v-slot:[`item.business_name`]='{ item }')
          span {{ item.business.business_name }} 
            span(v-if="item.hub") - ({{ item.hub.name }})        
        template(v-slot:[`item.obs`]='{ item }')
          span(v-if="item.obs")
            v-btn(color="blue" small text @click="openObs(item)")
              v-icon mdi-information-outline
              | INDICACIÓN
          span(v-else) 
        template(v-slot:[`item.status`]='{ item }')
          span {{$decoders.orderStatus(item.status)}}
  dialog-info(ref='dialogInfo') 
  action-business-drawer(ref='actionDrawer' @done='loadItems')

</template>

<script>
import ActionBusinessDrawer from "../../../components/admin/business/ActionBusinessDrawer.vue";
import DialogExcelBusinessOrders from "../../../components/admin/business/DialogExcelBusinessOrders.vue";
import DialogExcelBusinessOrdersByDispatch from "../../../components/admin/business/DialogExcelBusinessOrdersByDispatch.vue";
import DialogConfirm from "../../../components/shared/DialogConfirm.vue";
import DialogInfo from "../../../components/shared/DialogInfo.vue";
import moment from "moment";
export default {
  components: {
    DialogInfo,
    DialogConfirm,
    ActionBusinessDrawer,
    DialogExcelBusinessOrders,
    DialogExcelBusinessOrdersByDispatch
  },
  data() {
    return {
      loading: false,
      generalLoading: false,
      dataTableOptions: {},
      loadingTable: false,
      items: [],
      businesses: [],
      toDelete: null,
      totalItems: 0,
      emitir: null,
      currentObs: "",
      showObs: false,
      search: {
        docsPerPage: 20,
        page: 1,
        business_id: null,
        status: null,
        has_billing: null,
      },
      billing_combo: [
        { value: null, text: "Todos" },
        { value: true, text: "Facturado" },
        { value: false, text: "Sin facturar" },
      ],
      headers: [
        {
          text: "Empresa - (Hub)",
          align: "start",
          sortable: false,
          width: "250px",
          value: "business_name",
          class: "primary--text",
        },
        {
          text: "Fecha creación",
          align: "start",
          sortable: false,
          value: "created_at",
          class: "primary--text",
        },
        {
          text: "Estado",
          align: "start",
          sortable: false,
          value: "status",
          class: "primary--text",
        },
        {
          text: "Facturación",
          align: "start",
          sortable: false,
          value: "has_billing",
          class: "primary--text",
        },
        {
          text: "Nota de crédito",
          align: "start",
          sortable: false,
          value: "has_nota_credito",
          class: "primary--text",
        },
        {
          text: "Indicación",
          align: "center",
          sortable: false,
          value: "obs",
          class: "primary--text",
        },
        {
          text: "",
          align: "start",
          sortable: false,
          value: "actions",
          class: "primary--text",
        },
      ],
      text: "",
    };
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD/MM/YYYY HH:mm");
    },
    openEdit(item) {
      this.$router.push(`/corporativo/ordenes/${item.id}?action=edit`);
    },
    createOrder() {
      this.$router.push(`/corporativo/ordenes/${this.$uid()}?action=create`);
    },
    openObs(item) {
      this.currentObs = item.obs;
      this.showObs = true;
    },
    openFacturaModal(item) {
      this.emitir = item;
      let bodyMessage =
        "¿Está seguro que desea emitir una factura por esta compra?";
      if (item.ignore_pricing_on_new_order) {
        bodyMessage =
          "Antes de emitir una factura deberá modificar el precio de venta y unidades de este orden de compra";
      }
      this.$refs.dialogFactura.open("Emitir Factura", bodyMessage);
    },
    async emitirFactura() {
      if (this.emitir.ignore_pricing_on_new_order) {
        this.$refs.dialogFactura.close();
        this.$refs.dialogFactura.stopLoading();
        this.$router.push(
          `/corporativo/ordenes/${this.emitir.id}?action=edit&por_emitir=1`
        );
      } else {
        this.generalLoading = true;
        try {
          const response = await this.$store.dispatch(
            "postBusinessOrderEmitirFactura",
            this.emitir.id
          );
          let index = this.items.findIndex((o) => o.id === this.emitir.id);
          this.items[index].facturacion_tefacturo_response = response;
          this.items[index].has_billing = true;
          await this.$store.dispatch(
            "postDownloadFactura",
            response.ok_response
          );
        } catch (error) {
          let errorMessage = error;
          if (error.response?.data?.error_message) {
            errorMessage = error.response.data.error_message;
          }
          this.loadItems();
          this.$refs.dialogInfo.open("", errorMessage);
        }
        this.$refs.dialogFactura.close();
        this.$refs.dialogFactura.stopLoading();
        this.generalLoading = false;
      }
    },
    async downloadBilling(item) {
      this.generalLoading = true;
      await this.$store.dispatch(
        "postDownloadFactura",
        item.facturacion_tefacturo_response.ok_response
      );
      this.generalLoading = false;
    },
    wrapInParagraphs(str) {
      return str
        .split("\n")
        .map((line) => `<p>${line}</p>`)
        .join("");
    },
    async downloadNotaCredito(item) {
      this.generalLoading = true;
      let copy = JSON.parse(
        JSON.stringify(item.nota_credito_tefacturo_response.ok_response)
      );
      copy.tipo_documento = "NOTACREDITO";
      await this.$store.dispatch("postDownloadFactura", copy);
      this.generalLoading = false;
    },
    async loadItems(firstLoad) {
      if (!this.loadingTable) {
        this.generalLoading = true;
        this.loadingTable = true;
        try {
          this.loadingTable = true;
          let { items, total_items } = await this.$store.dispatch(
            "listBusinessOrders",
            this.search
          );
          this.items = items;
          this.totalItems = total_items;
          this.loadingTable = false;
          this.generalLoading = false;
        } catch (error) {
          this.$refs.dialogInfo.open(
            "No se pudo listar las ordenes",
            error.response.data.error_message
          );
          this.loadingTable = false;
          this.generalLoading = false;
        }
      }
    },
  },
  async beforeMount() {
    this.generalLoading = true;
    await this.loadItems(true);
    this.generalLoading = false;
    const items = await this.$store.dispatch("listBusinesses");
    items.unshift({
      id: null,
      business_name: "Todas las empresas",
    });
    this.businesses = items;
  },
};
</script>

<style lang="scss">
.customer-view {
  .v-data-table__wrapper {
    table {
      tbody {
        tr:nth-child(even) {
          td {
            background: transparent;
          }
        }
        tr:nth-child(odd) {
          background: #f4f9f7;
        }
        tr {
          &:hover {
            cursor: pointer;
            background-color: #c8fbe1 !important;
          }
        }
      }
    }
  }
}
</style>
