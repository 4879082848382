<template lang="pug">
v-container.px-8
  dialog-confirm(ref="dialogDeletePurchase" primaryText="Eliminar compra" secondaryText="Cancelar" @actionPressed="deletePurchaseAction")
  dialog-action-purchase(ref="dialogActionPurchase" @done="loadItems")
  v-row
    v-col(cols='12')
      v-toolbar(flat)
        v-toolbar-title.title Compras de la empresa
        v-spacer
        v-btn(color="primary" @click="createPurchase") Nueva Compra
      v-divider
  v-overlay(color='white' :value='generalLoading')
    v-progress-circular(color='primary' indeterminate size='64')
  v-row(wrap)
    v-col.d-flex.justify-space-between.align-center(cols='12')
      // filtros u otros controles...
      v-spacer
    v-col(cols='12')
      v-data-table(
        :items="purchases"
        :loading="loading"
        :headers="headers"
        :footer-props="{ itemsPerPageOptions: [10, 20, 50], 'items-per-page-text': 'Compras por página: ' }"
        no-data-text="No se encontraron compras"
      )
        template(v-slot:item.purchase_timestamp="{ item }")
          span(v-if="item.status != 'on_process'") {{ formatPurchaseDate(item.purchase_timestamp) }}
          .text-decoration-underline(v-else @click="openEdit(item)") {{ formatPurchaseDate(item.purchase_timestamp) }}
        template(v-slot:item.actions="{ item }")
          v-menu(bottom, close-on-content-click, left)
            template(v-slot:activator="{ on, attrs }")
              v-btn(icon v-bind="attrs" v-on="on")
                v-icon mdi-dots-vertical
            v-list
              v-list-item(@click="moveToEdit(item)")
                v-list-item-title Ver compra
              v-list-item(@click="confirmDelete(item)" v-if="item.status !== 'approved'")
                v-list-item-title Eliminar
        template(v-slot:item.status="{ item }")
          v-chip(label :color="$decoders.purchaseStatusColor(item.status)") {{ $decoders.purchaseStatus(item.status) }}
        template(v-slot:item.buyer="{ item }")
          span(v-if="item.buyer") {{ item.buyer.first_name }} {{ item.buyer.last_name }}
          div.text-caption(v-if="item.buyer") {{ item.buyer.email }}
        template(v-slot:item.total_products_cost="{ item }")
          span {{ formatCurrency(item.total_items_cost) }}
        template(v-slot:item.total_extra_costs="{ item }")
          span {{ formatCurrency(item.total_extra_costs) }}
        template(v-slot:item.total_cost="{ item }")
          span {{ formatCurrency(item.total_cost) }}
        // Nueva columna: Deuda Total
        template(v-slot:item.total_debt="{ item }")
          span(v-if="getPurchaseDebt(item) !== ''" :class="[getPurchaseDebt(item) === 0 ? 'success--text': 'red--text']") {{ formatCurrency(getPurchaseDebt(item)) }}
</template>

<script>
import DialogConfirm from "../../../components/shared/DialogConfirm.vue";
import DialogActionPurchase from "../../../components/admin/business/DialogActionPurchase.vue";
export default {
  components: {
    DialogConfirm,
    DialogActionPurchase,
  },
  data() {
    return {
      loading: false,
      generalLoading: false,
      purchases: [],
      buyers: [],
      totalItems: 0,
      purchaseToDelete: null,
      search: {
        docsPerPage: 10,
        page: 1,
        buyer_id: null,
        status: null,
      },
      status_combo: [
        { value: null, text: "Todos" },
        { value: "on_process", text: "En proceso" },
        { value: "completed", text: "Completado" },
        { value: "cancelled", text: "Cancelado" },
      ],
      headers: [
        { text: "Estado", align: "start", sortable: false, value: "status", class: "primary--text" },
        { text: "Fecha y hora de compra", align: "start", sortable: false, value: "purchase_timestamp", class: "primary--text" },
        { text: "Comprador", align: "center", sortable: false, value: "buyer", class: "primary--text" },
        { text: "Total Productos", align: "start", sortable: false, value: "total_products_cost", class: "primary--text" },
        { text: "Total Costos Extras", align: "start", sortable: false, value: "total_extra_costs", class: "primary--text" },
        { text: "Costo Total", align: "start", sortable: false, value: "total_cost", class: "primary--text" },
        { text: "Deuda Total", align: "right", sortable: false, value: "total_debt", class: "primary--text" },
        { text: "", align: "start", sortable: false, value: "actions", class: "primary--text" },
      ],
    };
  },
  computed: {
    paginatedPurchases() {
      const start = (this.search.page - 1) * this.search.docsPerPage;
      const end = start + this.search.docsPerPage;
      return this.purchases.slice(start, end);
    },
  },
  methods: {
    moveToEdit(item) {
      this.$router.push(`/compras/${item.id}`);
    },
    formatPurchaseDate(timestamp) {
      const date = new Date(timestamp * 1000);
      return new Intl.DateTimeFormat("es-PE", {
        dateStyle: "short",
        timeStyle: "short",
        timeZone: "America/Lima",
      }).format(date);
    },
    formatCurrency(value) {
      return new Intl.NumberFormat("es-PE", {
        style: "currency",
        currency: "PEN",
      }).format(value);
    },
    openEdit(item) {
      this.$refs.dialogActionPurchase.open("edit", item);
    },
    createPurchase() {
      this.$refs.dialogActionPurchase.open("create");
    },
    confirmDelete(item) {
      this.purchaseToDelete = item;
      this.$refs.dialogDeletePurchase.open("Eliminar Compra", "¿Está seguro que desea eliminar esta compra?");
    },
    async deletePurchaseAction() {
      if (this.purchaseToDelete) {
        try {
          await this.$store.dispatch("deletePurchase", { purchaseId: this.purchaseToDelete.id });
          await this.loadItems();
          this.$refs.dialogDeletePurchase.close();
        } catch (error) {
          this.$refs.dialogInfo.open("Error", "No se pudo eliminar la compra");
        }
      }
    },
    filterPurchases() {
      this.search.page = 1;
    },
    async loadItems() {
      this.generalLoading = true;
      try {
        let purchases = await this.$store.dispatch("loadPurchases");
        this.purchases = purchases;
        this.totalItems = purchases.length;
      } catch (error) {
        this.$refs.dialogInfo.open("Error", "No se pudo cargar las compras.");
      }
      this.generalLoading = false;
    },
    // Calcula la deuda total de la compra:
    // Solo se consideran los elementos donde is_credit es true o false.
    // Para cada elemento:
    // - Si is_credit es true y total_payment_date no está definido (o es vacío), la deuda es (total_price - partial_payment).
    // - Si is_credit es true y total_payment_date tiene valor, se considera pagado (deuda = 0).
    // - Si is_credit es false, se entiende que el sistema de créditos funciona pero no se usó, por lo que la deuda es 0.
    // Si no hay ningún elemento con is_credit (true o false), se retorna cadena vacía.
    getPurchaseDebt(purchase) {
      const items = purchase.items || [];
      const extras = purchase.extra_costs || [];
      // Elementos con crédito activado: true o false
      const creditItems = items.filter(i => i.is_credit === true || i.is_credit === false);
      const creditExtras = extras.filter(e => e.is_credit === true || e.is_credit === false);
      // Si ninguno utiliza el sistema (es decir, ambos arrays están vacíos), retornamos cadena vacía.
      if (creditItems.length === 0 && creditExtras.length === 0) return "";
      // Para los elementos con is_credit === true se calcula la deuda.
      const debtItems = items.filter(i => i.is_credit === true).reduce((acc, i) => {
        if (i.total_payment_date && i.total_payment_date.trim() !== "") return acc;
        const debt = i.total_price - i.partial_payment;
        return acc + (debt > 0 ? debt : 0);
      }, 0);
      const debtExtras = extras.filter(e => e.is_credit === true).reduce((acc, e) => {
        if (e.total_payment_date && e.total_payment_date.trim() !== "") return acc;
        const debt = e.total_price - e.partial_payment;
        return acc + (debt > 0 ? debt : 0);
      }, 0);
      // Los elementos con is_credit === false se consideran sin deuda (0)
      return debtItems + debtExtras;
    },
  },
  async beforeMount() {
    await this.loadItems();
  },
};
</script>

<style lang="scss">
.purchase-view {
  .v-data-table__wrapper {
    table {
      tbody {
        tr:nth-child(even) {
          td { background: transparent; }
        }
        tr:nth-child(odd) {
          background: #f4f9f7;
        }
        tr {
          &:hover { cursor: pointer; background-color: #c8fbe1 !important; }
        }
      }
    }
  }
}
</style>
