<template lang="pug">
v-dialog(v-model="show" max-width="500px" persistent)
  v-card.py-2
    v-card-title.d-flex.justify-space-between
      h3.text-center {{ title }}
    v-card-text
      v-form(v-model="valid" ref="form")
        v-autocomplete(
          v-model="form.product_sku" 
          :items="productList" 
          item-value="sku" 
          item-text="name" 
          :rules="[$rules.required]" 
          label="Producto comprado" 
          outlined dense
        )
        v-text-field(
          v-model.number="form.quantity" 
          type="number" 
          :rules="[$rules.required]" 
          label="Cantidad comprada" 
          :suffix="product.unit" 
          outlined dense
        )
        v-currency-field(
          dense outlined 
          prefix="S/" 
          :min="0" 
          :rules="[$rules.numeric, $rules.minValue(form.total_price, 0)]" 
          label="Total de la compra" 
          v-model.number="form.total_price"
        )
        v-text-field(
          v-model="form.supplier" 
          :rules="[$rules.required]" 
          label="Proveedor" 
          outlined dense
        )
        // Campo para indicar si es a crédito
        v-switch(
          v-model="form.is_credit" 
          label="¿Compra a crédito?" 
          outlined dense
        )
        // Mostrar campos adicionales solo si se activa la compra a crédito
        v-slide-y-transition
          div(v-if="form.is_credit")
            v-text-field(
              v-model.number="form.credit_days" 
              type="number" 
              :rules="[$rules.required]" 
              label="Días de crédito" 
              outlined dense
            )
            v-currency-field(
              dense outlined 
              prefix="S/" 
              :min="0" 
              :rules="[$rules.numeric]" 
              label="Pago parcial" 
              v-model.number="form.partial_payment"
            )
            v-text-field(
              v-model="form.total_payment_date" 
              label="Fecha de pago total" 
              outlined dense 
              placeholder="DD/MM/AAAA"
              type="date"
              clearable
            )
    .py-3.d-flex.justify-center
      v-btn(color="primary" outlined @click="close") Cerrar
      v-btn.ml-8(
        color="primary" 
        @click="confirmed" 
        :loading="loading" 
        :disabled="!valid"
      ) {{ action === 'create' ? 'Crear' : 'Editar' }}
</template>
  
  <script>
  export default {
    props: {
      purchase: {
        type: Object,
        required: true
      },
    },
    data() {
      return {
        form: {
          id: null,
          product_sku: "",
          product_name: "",
          quantity: 0,
          supplier: "",
          unit: "",
          total_price: 0,
          unit_price: 0,
          // Campos de crédito:
          is_credit: false,        // se define false por defecto, en registros antiguos es null
          credit_days: 0,
          partial_payment: 0,
          total_payment_date: "",
        },
        show: false,
        loading: false,
        action: 'create',
        valid: false,
      };
    },
    computed: {
      title() {
        return this.action === 'create' ? 'NUEVO PRODUCTO' : 'EDITAR PRODUCTO';
      },
      product() {
        return this.productList.find(p => p.sku === this.form.product_sku) || {};
      },
      productList() {
        return this.$store.state.products.productsSimple;
      }
    },
    methods: {
      close() {
        this.show = false;
        this.loading = false;
      },
      open(action, item) {
        this.action = action;
        if (action === 'edit') {
          this.form = { ...item };
        } else {
          this.form = {
            id: null,
            product_sku: "",
            product_name: "",
            quantity: 0,
            supplier: "",
            unit: "",
            total_price: 0,
            unit_price: 0,
            is_credit: false,
            credit_days: 0,
            partial_payment: 0,
            total_payment_date: "",
          };
        }
        this.show = true;
        this.loading = false;
        this.$nextTick(() => {
          this.$refs.form.resetValidation();
        });
      },
      async confirmed() {
        this.loading = true;
        try {
          const unitPrice = Number((this.form.total_price / this.form.quantity).toFixed(2));
          this.form.product_name = this.product.name;
          this.form.unit_price = unitPrice;
          this.form.unit = this.product.unit;
          // Aquí se envía el payload con los nuevos campos de crédito
          await this.upsertPurchaseItem(this.purchase.id, this.form);
          this.$emit('done');
          this.close();
        } catch (error) {
          console.error(error);
        }
      },
      async upsertPurchaseItem(purchaseId, purchaseItem) {
        try {
          const data = await this.$store.dispatch('upsertPurchaseItem', { purchaseId, payload: purchaseItem });
          return data;
        } catch (error) {
          console.error("Error al actualizar el ítem de la compra:", error);
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .text-center {
    text-align: center;
  }
  </style>
  